import React from 'react'
import Nav from '../common/Nav.js'

function Introduction() {

  return (
    <div className="introduction_screen_wrapper">
      <section className="introduction">
        <h3 className='tag'>{'<html>'}</h3>
        <h3 className="first_indent tag">{'<body>'}</h3>
        <h3 className="second_indent tag">{'<h1>'}</h3>
        <h1 className='second_indent topic_title_name'>Hi, I am <span className="my_name">Christian Creenan</span></h1>
        <h3 className="second_indent tag">{'</h1>'}</h3>
        <h3 className="second_indent tag">{'<h3>'}</h3>
        <h1 className='second_indent topic_title'>Full-stack Engineer</h1>
        <h3 className="second_indent tag">{'</h3>'}</h3>
        <h3 className="first_indent tag">{'<body>'}</h3>
        <h3 className='tag'>{'</html>'}</h3>
      </section>
      <div className="ball_animation_wrapper">
        <div className="wall"></div>
        <div className="ball"></div>
        <div className="wall"></div>
      </div>
      <Nav />
    </div>
  )

}

export default Introduction