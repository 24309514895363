/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Nav from '../../common/Nav.js'
import video from '../../media/r_quiz_edit.mp4'

function Quiz() {



  return (
    <div className='project_wrapper'>
      <div className='caishen_wrapper'>
        <div className='caishen_details'>
          <h3 className='tag'>{'<html>'}</h3>
          <h3 className='first_indent tag'>{'<body>'}</h3>
          <h3 className='second_indent tag'>{'<h1>'}</h3>
          <h1 className='second_indent topic_title project_title'>React Quiz</h1>
          <h3 className='second_indent tag'>{'</h1>'}</h3>
          <h3 className='second_indent tag'>{'<a>'}</h3>
          <a className='second_indent open_application' href="https://reactquizchristian.netlify.app/" target="_blank" rel="noopener noreferrer">Open the application<i className="fas fa-external-link-alt"></i></a>
          <a className='second_indent open_application' href="https://github.com/ChrisTheCyberCoder/SEI-Project-2" target="_blank" rel="noopener noreferrer">View code:<i className="fab fa-github-square view_code"></i></a>
          <h3 className='second_indent tag'>{'</a>'}</h3>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'> This React application is essentially a quiz. The rules are similar to the game, Who Wants To Be A Millionnaire. Players are given a random selection of 15 questions in their chosen topic. Of those 15 questions, each question has 4 multiple choice answers and only one is correct. Players can choose to use the help lines, ask the audience or go 50:50. Players win sums of money after they answer a question correctly. However, money is only secured if they choose to take the money before the next question is answered, or if they answer 5 questions consecutively correctly, they are secured an amount of money seen on the money board. 
          </p>
          <p className='second_indent tag'>{'</p>'}</p>
          <h1 className='first_indent tag'>{'<body>'}</h1>
          <h1 className='tag'>{'</html>'}</h1>
        </div>
        <div className='side_project_picture_pokezon'>
          <video autoPlay={true} controls="controls" className="video_quiz">
            <source src={video} type='video/mp4'/>
            <source src={video} type='video/ogg'/>
          </video>
        </div>
      </div>
      <Nav />
    </div>
  )
}

export default Quiz 