/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Nav from '../../common/Nav.js'
import video1 from '../../media/caishen_banking_app.mp4'
import video2 from '../../media/caishen_banking_app_1.mp4'

function Caishen() {

  return (
    
    <div className='project_wrapper'>
      <div className='caishen_wrapper'>
        <div className='caishen_details'>
          <h3 className='tag'>{'<html>'}</h3>
          <h3 className='first_indent tag'>{'<body>'}</h3>
          <h3 className='second_indent tag'>{'<h1>'}</h3>
          <h1 className='second_indent topic_title project_title'>Caishen Bank 1.0</h1>
          <h6 className="second_indent coming_soon">Caishen Bank 2.0 Coming Soon!</h6>
          <h3 className='second_indent tag'>{'</h1>'}</h3>
          <h3 className='second_indent tag'>{'<a>'}</h3>
          <a className='second_indent open_application' href="https://caishenbankingapp.herokuapp.com" target="_blank" rel="noopener noreferrer">Open the application<i className="fas fa-external-link-alt"></i></a>
          <a className='second_indent open_application' href="#"><span className="please_contact">Please get in contact to view the code</span><i className="fab fa-github-square view_code"></i></a>
          <h3 className='second_indent tag'>{'</a>'}</h3>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'> I built this banking application using Python, Django, Postgresql for the backend and JS, React, HTML and CSS for the front-end. It has many functionalities. A customer can register/login. They are able to create a savings account, and a deposit account. They can transfer money between accounts, make payments to other customers, add money to their accounts, view their pin, check their user profile, receive notifications, check recent transactions etc. <br /><br />
          
          There are also a number of security elements I have programmed in the back-end to make sure that only the customer logged in can view their own information and undergo functionalities of their choosing. There are also other measures in the back-end to prevent customers from duplicating accounts, preventing special characters that a customer can input etc. These requests are blocked in the back-end to prevent anyone from bypassing the front-end.
          </p>
          <p className='second_indent tag'>{'</p>'}</p>
          <p className='second_indent tag'>{'<h2>'}</p>
          <h2 className='second_indent second_topic_title'>Want to try?</h2>
          <p className='second_indent tag'>{'</h2>'}</p>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'>Use the information below to login, or register an account. You can register two accounts if you want to experience the interaction with another customer.</p><br />
          <p className='second_indent para'>Email: christian@email.com</p>
          <p className='second_indent para'>Password: passpassAP1</p>
          <p className='second_indent tag'>{'</p>'}</p>
          <h1 className='first_indent tag'>{'<body>'}</h1>
          <h1 className='tag'>{'</html>'}</h1>
        </div>
        <div className='side_project_picture'>
          <video autoPlay={true} controls="controls" className="video_caishen">
            <source src={video1} type='video/mp4'/>
            <source src={video1} type='video/ogg'/>
          </video>
          <video autoPlay={true} controls="controls" className="video_caishen">
            <source src={video2} type='video/mp4'/>
            <source src={video2} type='video/ogg'/>
          </video>
        </div>
      </div>
      <Nav />
    </div>
  )
}

export default Caishen 