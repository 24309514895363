/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Nav from '../../common/Nav.js'
import video1 from '../../media/caishen_2_video_updated.mp4'


function CaishenTwo() {

  return (
    
    <div className='project_wrapper'>
      <div className='caishen_wrapper'>
        <div className='caishen_details'>
          <h3 className='tag'>{'<html>'}</h3>
          <h3 className='first_indent tag'>{'<body>'}</h3>
          <h3 className='second_indent tag'>{'<h1>'}</h3>
          <h1 className='second_indent topic_title project_title'>Caishen Bank 2.0</h1>
          <h3 className='second_indent tag'>{'</h1>'}</h3>
          <h3 className='second_indent tag'>{'<a>'}</h3>
          <a className='second_indent open_application' href="https://bankingcaishen2.herokuapp.com/" target="_blank" rel="noopener noreferrer">Open the application<i className="fas fa-external-link-alt"></i></a>
          <a className='second_indent open_application' href="#"><span className="please_contact">Please get in contact to view the code</span><i className="fab fa-github-square view_code"></i></a>
          <h3 className='second_indent tag'>{'</a>'}</h3>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'> Caishen 2.0 has a number of new features and a new, updated back-end system built using Python, Django and a Postgresql database. For example, for one of the features, customers can now receive live support by chatting to one of the Administrators at Caishen using a chat messenger. Administrators have their own logins accessible by them only. They have a chat interface, which pulls in all of the customer messages to which they can respond to and manage. The whole system is updated live and secure, only Administrators and the customer logged in can respond and view the messages created and sent. <br />< br />
          
          The back-end system is even more secure and robust than the first version. For example, when sending payments, customers also have to make sure that name of the recipient also matches that of the account number. Error handling at the back-end has also been enhanced, so that the customer is aware at every aspect of their journey through the application.< br />< br />
          Please do view the application to see all the new features available, and to feel the new backend system at play. 
          </p>
          <p className='second_indent tag'>{'</p>'}</p>
          <p className='second_indent tag'>{'<h2>'}</p>
          <h2 className='second_indent second_topic_title'>Want to try?</h2>
          <p className='second_indent tag'>{'</h2>'}</p>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'>Use the information below to login. You can also create more users: </p>
          <p className='second_indent para'>Email: christian@email.com</p>
          <p className='second_indent para'>Password: passpassAP1</p><br />
          <p className='second_indent para'>Use the information below to login as the administrator: </p>
          <p className='second_indent para'>Email: admin@email.com</p>
          <p className='second_indent para'>Password: pass</p>
          <p className='second_indent tag'>{'</p>'}</p>
          <h1 className='first_indent tag'>{'<body>'}</h1>
          <h1 className='tag'>{'</html>'}</h1>
        </div>
        <div className='side_project_picture_caishen_2'>
          <video autoPlay={true} controls="controls" className="video_caishen2">
            <source src={video1} type='video/mp4'/>
            <source src={video1} type='video/ogg'/>
          </video>
        </div>
      </div>
      <Nav />
    </div>
  )
}

export default CaishenTwo