/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import Nav from '../../common/Nav.js'
import video from '../../media/pac_recording.mp4'

function Homerman() {



  return (
    <div className='project_wrapper'>
      <div className='caishen_wrapper'>
        <div className='caishen_details'>
          <h3 className='tag'>{'<html>'}</h3>
          <h3 className='first_indent tag'>{'<body>'}</h3>
          <h3 className='second_indent tag'>{'<h1>'}</h3>
          <h1 className='second_indent topic_title project_title'>Pac-man/Homer-man</h1>
          <h3 className='second_indent tag'>{'</h1>'}</h3>
          <h3 className='second_indent tag'>{'<a>'}</h3>
          <a className='second_indent open_application' href="https://christhecybercoder.github.io/SEI-Project-1/" target="_blank" rel="noopener noreferrer">Open the application<i className="fas fa-external-link-alt"></i></a>
          <a className='second_indent open_application' href="https://github.com/ChrisTheCyberCoder/SEI-Project-1" target="_blank" rel="noopener noreferrer">View code:<i className="fab fa-github-square view_code"></i></a>
          <h3 className='second_indent tag'>{'</a>'}</h3>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'> This game was one of the first projects I did. It was built using Vanilla Javascript. It is essentialy a re-make of the famous arcade game, Pac-man. The aim of the game is to collect all the pink donuts without getting caught by one of the simpson villains. The green donuts turn the simpson villians into aliens that can be consumed by Homer after eating one. You win points for every pink donut that is consumed, and donuts must be eaten to advance to the next level where the Simpson Villians get progressively harder. The Villians do move randomly on this version, however, another version will soon be updated on this website and it uses the A* alogirithm. 
          </p>
          <p className='second_indent tag'>{'</p>'}</p>
          <h1 className='first_indent tag'>{'<body>'}</h1>
          <h1 className='tag'>{'</html>'}</h1>
        </div>
        <div className='side_project_picture_pokezon'>
          <video autoPlay={true} controls="controls" className="video_homerman">
            <source src={video} type='video/mp4'/>
            <source src={video} type='video/ogg'/>
          </video>
        </div>
      </div>
      <Nav />
    </div>
  )
}

export default Homerman 