/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Nav from '../common/Nav.js'

function Skill() {

  return (

    <div className='skills_wrapper'>
      <section className='my_skills'>
        <h3 className='tag'>{'<html>'}</h3>
        <h3 className='first_indent tag'>{'<body>'}</h3>
        <h3 className='second_indent tag'>{'<h1>'}</h3>
        <h1 className='second_indent topic_title'>Skills</h1>
        <h3 className='second_indent tag'>{'</h1>'}</h3>
        <p className='second_indent tag'>{'<p>'}</p>
        <p className='second_indent para'>
        Having graduated from a 12-week Software Engineering bootcamp at General Assembly, I learnt and was able to practice with several technologies. These are revealed in the cube animation I have created - to the right. <br /><br />
         
        Since course completion, I have honed my skills developing and practicing those technologies: in particular, Python - using the Django framework and a Postgresql database for the back-end. I have also acquired additional skills in the front-end with Javascript, the React framework, CSS and HTML. <br /><br />

        Two of the four projects I chose to progress at General Assembly - and for which I was solely responsible - were back-end heavy.  <br /><br />
         
        The third project was a team project – an e-commerce website. I solely built and developed the back-end in Nodejs and Express using a MongoDB database. I was also responsible for the React/Javascript requirements for those functionalities made at the back-end.  <br /><br />

        The fourth project was a banking application for which I was solely responsible. This was chosen for its complexity and challenging nature in the back-end. I was able to use a considerable amount of Python and Django using a Postgresql database. I also used React, HTML and CSS to send the required form-data to the back-end.
        </p>
        <p className='second_indent tag'>{'</p>'}</p>
        <h1 className='first_indent tag'>{'</body>'}</h1>
        <h1 className='tag'>{'</html>'}</h1>
      </section>
      <div id='wrapper'>
        <div className='box-area'>
          <div id='box-front' className='box'>
            <h6>Back-end</h6>
            <div className="icons_container">
              <img src="https://img.icons8.com/color/100/000000/python.png"/>
              <img src="https://img.icons8.com/color/100/000000/django.png"/>
              <img src="https://img.icons8.com/windows/100/000000/node-js.png"/>
              <img src='https://cdn.iconscout.com/icon/free/png-256/express-9-1175170.png'/>
            </div>
          </div>
          <div id='box-right' className='box'>
            <h6>Front-end</h6>
            <div className="icons_container">
              <img src="https://img.icons8.com/color/100/000000/javascript.png"/>
              <img src="https://img.icons8.com/ios-filled/100/000000/html-5--v1.png"/>
              <img src="https://img.icons8.com/color/100/000000/css3.png"/>
              <img src="https://img.icons8.com/color/100/000000/sass.png"/>
              <img src="https://img.icons8.com/officel/100/000000/react.png"/>
            </div>
          </div>
          <div id='box-back' className='box'>
            <h6>Others</h6>
            <div className="icons_container">
              <img src='https://img.icons8.com/windows/100/000000/git-squared.png'/>
              <img src="https://img.icons8.com/material-sharp/100/000000/github.png"/>
              <img src='https://img.icons8.com/color/100/000000/heroku.png'/>
              <img src='https://img.icons8.com/color/100/000000/visual-studio-code-2019.png'/>
              <img src="https://img.icons8.com/windows/100/000000/yarn-logo.png"/>
              <img src="https://img.icons8.com/windows/100/000000/npm.png"/>
              <img src='https://png.pngitem.com/pimgs/s/463-4634376_insomnia-rest-client-icon-hd-png-download.png'></img>
              <img src='https://tableplus.com/resources/favicons/apple-icon-180x180.png'></img>
            </div>
          </div>
          <div id='box-left' className='box'>
            <h6>Databases</h6>
            <div className="icons_container">
              <img src="https://img.icons8.com/color/100/000000/postgreesql.png"/>
              <img src='https://img.icons8.com/fluent/100/000000/mysql-logo.png'/>
              <img src='https://img.icons8.com/color/100/000000/mongodb.png'/>
              <img src='https://cms-assets.tutsplus.com/uploads/users/34/posts/29527/preview_image/mongoose.jpg'/>
            </div>
          </div>
          <div id='box-top' className='box'>
          </div>
          <div id='box-bottom' className='box'>
          </div>
        </div>
      </div>
      <Nav />
    </div>
  )
}

export default Skill