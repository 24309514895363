import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import Nav from './common/Nav.js'
import Introduction from './components/Introduction.js'
import Skill from './components/Skill.js'
import AboutMe from './components/AboutMe.js'
import Contact from './components/Contact.js'
import Projects from './components/Projects.js'
import Caishen from './components/projects/Caishen.js'
import Homerman from './components/projects/Homerman'
import Pokezon from './components/projects/Pokezon.js'
import Quiz from './components/projects/Quiz.js'
import CaishenTwo from './components/projects/CaishenTwo'


function App() {
  return (

    <BrowserRouter>
      {/* <Nav /> */}
      <Switch>
        <Route exact path="/" component={Introduction} />
        <Route exact path="/skill" component={Skill} />
        <Route exact path="/aboutme" component={AboutMe} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/caishen" component={Caishen} />
        <Route exact path="/caishen2" component={CaishenTwo} />
        <Route exact path="/homerman" component={Homerman} />
        <Route exact path="/pokezon" component={Pokezon} />
        <Route exact path="/quiz" component={Quiz} />
      </Switch>
    </BrowserRouter>
    
  )
}

export default App
