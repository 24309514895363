/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Nav from '../common/Nav.js'

function Contact() {
  
  return (
    <div className="contact_wrapper">
      <div className="contact_icons_wrapper">
        <h3 className='first_indent tag'>{'<body>'}</h3>
        <h3 className='second_indent tag'>{'<h1>'}</h3>
        <h1 className='second_indent topic_title'>Contact</h1>
        <h3 className='second_indent tag'>{'</h1>'}</h3>
        <h3 className='second_indent tag'>{'<footer>'}</h3>
        <h3 className='third_indent tag'>{'<h3>'}</h3>
        <a href="https://github.com/ChrisTheCyberCoder" target="_blank" rel="noopener noreferrer"><i
          className="fab fa-github-square third_indent"><span>github.com/ChrisTheCyberCoder</span></i></a>
        <h3 className='third_indent tag'>{'</h3>'}</h3>
        <h3 className='third_indent tag'>{'<h3>'}</h3>
        <a href="https://linkedin.com/in/christiancreenan" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin third_indent"><span>linkedin.com/in.christiancreenan</span></i></a>
        <h3 className='third_indent tag'>{'</h3>'}</h3>
        <h3 className='third_indent tag'>{'<h3>'}</h3>
        <a href="#"><i className="fas fa-envelope-square third_indent" target="_blank" rel="noopener noreferrer"><span>christiancreenan@gmail.com</span></i></a>
        <h3 className='third_indent tag'>{'</h3>'}</h3>
        <h3 className='second_indent tag'>{'</footer>'}</h3>
        <h1 className='first_indent tag'>{'</body>'}</h1>
      </div>
      <Nav />
    </div>
  )
}

export default Contact