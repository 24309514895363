import React from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'

function Nav() {

  return (
    <nav className="nav_wrapper">
      <h4>Christian Creenan<br /><br /><span>Full-Stack Engineer</span></h4>
      <Loader type="Rings" color="#00BFFF" className="nav_load" />
      <ul>
        <Link to='/'>
          <li>Home</li>
        </Link>
        <Link to='/aboutme'>
          <li>About Me</li>
        </Link>
        <Link to='/skill'>
          <li>Skills</li>
        </Link>
        <Link to='/projects'>
          <li>Projects</li>
        </Link>
        <Link to='/contact'>
          <li>Contact</li>
        </Link>
      </ul>
      <div className="contact_icons">
        <a href="https://github.com/ChrisTheCyberCoder" target="_blank" rel="noopener noreferrer"><i className="fab fa-github-square"></i></a>
        <a href="https://linkedin.com/in/christiancreenan" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
      </div>
    </nav>
  )

}

export default Nav 