import React from 'react'
import Nav from '../common/Nav.js'

function AboutMe() {

  return (
    <div className="aboutme_screen_wrapper">
      <div className="triangle"></div>
      <section className='aboutme_wrapper'>
        <h3 className='tag'>{'<html>'}</h3>
        <h3 className='first_indent tag'>{'<body>'}</h3>
        <h3 className='second_indent tag'>{'<h1>'}</h3>
        <h1 className='second_indent topic_title'>About Me</h1>
        <h3 className='second_indent tag'>{'</h1>'}</h3>
        <p className='second_indent tag'>{'<p>'}</p>
        <p className='second_indent para'>
          I am a Full-Stack engineer from London. My interest in engineering and, specifically, computers started at a young age. Designing and building from small components is immensely pleasurable. I am driven by the logic, analysis and creativity required to obtain the optimum solutions.<br/><br/>
          
          The main driver sparking my interest in Software Engineering is the requisite of ‘deep thought’. I enjoy reflecting on the logic involved, taking things apart and assembling them again, being analytical, working in teams and continuously learning new things. <br/><br/>
          
          I recently graduated from a Software Engineering Bootcamp at General Assembly and continue to build on my programming skills.<br/><br/>

          Organised and structured, I like to carefully plan projects before I start programming. I am interested in both backend and frontend development. <br/><br/>

          Outside of software engineering, I practice martial arts - in particular, Muay Thai. I also enjoy playing video games and football and travel. 
        </p>
        <p className='second_indent tag'>{'</p>'}</p>
        <h1 className='first_indent tag'>{'<body>'}</h1>
        <h1 className='tag'>{'</html>'}</h1>
      </section>
      <Nav />
    </div>
  )

}

export default AboutMe