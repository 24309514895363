import React from 'react'
import Nav from '../common/Nav.js'
import { Link } from 'react-router-dom'


function Projects() {


  return (
    <div className="projects_wrapper">
      <div className='all_projects'>
        <Link to = '/caishen2'>
          <div className="caishen_two">
            <h3>Caishen Bank 2.0</h3>
          </div>
        </Link>
        <Link to = '/caishen'>
          <div className="project_four">
            <h3>Caishen Bank 1.0</h3>
          </div>
        </Link>
        <Link to = '/quiz'>
          <div className="project_three">
            <h3>React Quiz</h3>
          </div>
        </Link>
        <Link to = '/pokezon'>
          <div className="project_two">
            <h3>Pokézon-ecommerce</h3>
          </div>
        </Link>
        <Link to = '/homerman'>
          <div className="project_one">
            <h3>Pac-man/Homer-man</h3>
          </div>
        </Link>
      </div>
      <Nav />
    </div>
  )
}

export default Projects