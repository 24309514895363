/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Nav from '../../common/Nav.js'
import video1 from '../../media/pokezon_recording.mp4'
import video2 from '../../media/pokezon_recording_2.mp4'

function Pokezon() {



  return (
    <div className='project_wrapper'>
      <div className='caishen_wrapper'>
        <div className='caishen_details'>
          <h3 className='tag'>{'<html>'}</h3>
          <h3 className='first_indent tag'>{'<body>'}</h3>
          <h3 className='second_indent tag'>{'<h1>'}</h3>
          <h1 className='second_indent topic_title project_title'>Pokézon</h1>
          <h3 className='second_indent tag'>{'</h1>'}</h3>
          <h3 className='second_indent tag'>{'<a>'}</h3>
          <a className='second_indent open_application' href="https://pokezonwebapplication.herokuapp.com/" target="_blank" rel="noopener noreferrer">Open the application<i className="fas fa-external-link-alt"></i></a>
          <a className='second_indent open_application' href="https://github.com/ChrisTheCyberCoder/SEI-Project-3" target="_blank" rel="noopener noreferrer">View code:<i className="fab fa-github-square view_code"></i></a>
          <h3 className='second_indent tag'>{'</a>'}</h3>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'> This application, a team project, was built using a MongoDB database, NodeJS, Express and Mongoose in the back-end, and React, HTML and CSS in the front-end.<br /> <br />
          I was responsible for building and developing the back-end whilst also taking responsbility of those linked functionalities in the front-end with React. Some of the functionaliites of this together include updating, deleting and adding items to the basket, registering and logging in, creating comments and deleting them, coming up with an algorithm to load pre-existing comments to the website, coding the security logic to prevent customers from unauthorised access and the error handling in the backend and the frontend etc. <br /> <br />
          The website was beautifully designed by a team member who was also responsible for the animations, seeding the items data, and coding the pagination item logic of the page without using plugins. Another member was responsible for a payment-form component, which had requirements needed from a customer in the front-end. 
          </p>
          <p className='second_indent tag'>{'</p>'}</p>
          <p className='second_indent tag'>{'<h2>'}</p>
          <h2 className='second_indent second_topic_title'>Want to try?</h2>
          <p className='second_indent tag'>{'</h2>'}</p>
          <p className='second_indent tag'>{'<p>'}</p>
          <p className='second_indent para'>Use the information below to login, or register an account.</p><br />
          <p className='second_indent para'>Email: christian@email.com</p>
          <p className='second_indent para'>Password: pass</p>
          <p className='second_indent tag'>{'</p>'}</p>
          <h1 className='first_indent tag'>{'<body>'}</h1>
          <h1 className='tag'>{'</html>'}</h1>
        </div>
        <div className='side_project_picture_pokezon'>
          <video autoPlay={true} controls="controls" className="video_pokezon" width="90%">
            <source src={video1} type='video/mp4'/>
            <source src={video1} type='video/ogg'/>
          </video>
          <video autoPlay={true} controls="controls" className="video_pokezon">
            <source src={video2} type='video/mp4'/>
            <source src={video2} type='video/ogg'/>
          </video>
        </div>
      </div>
      <Nav />
    </div>
  )
}

export default Pokezon 